// 企业福利显示类型
export const COMPANY_WELFARE_SHOW = {
  // 全部
  ALL: 1,
  // 自动
  AUTO: 2,
  // 手动
  MANUAL: 3,
};

export const LocalStorageKey = "EC_BENEFITS_EMPLOYMENT_LIST";
