<template>
  <a-form-model
    ref="formRef"
    :model="formData"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <div class="list-wrapper">
      <a-row :gutter="10" v-for="(item, index) in formData.list" :key="item.id">
        <a-col :span="2">
          <div class="list-sn">{{ index + 1 }}</div>
        </a-col>
        <a-col :span="10">
          <a-form-model-item
            label=""
            :prop="'list.' + index + '.year'"
            :rules="{ required: true, message: $t('welfarePlat.companyBenefits.employment.yearRequired') }"
          >
            <a-input-number
              v-model="item.year"
              :min="0"
              :precision="0"
              :placeholder="$t('welfarePlat.companyBenefits.employment.year', { unit: $t('datetime.unit.year') })"
              style="width: 100%;"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <a-form-model-item
            label=""
            :prop="'list.' + index + '.points'"
            :rules="{ required: true, message: $t('welfarePlat.companyBenefits.employment.pointsRequired') }"
          >
            <a-input-number
              v-model="item.points"
              :min="0"
              :precision="2"
              :placeholder="$t('welfarePlat.companyBenefits.employment.points', { name: $store.state.user.platformConf.moneyName })"
              style="width: 100%;"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="2">
          <a-button
            type="link"
            icon="delete"
            @click="onDelete(item, index)"
            class="del-btn"
            :title="$t('button.remove')"
            :disabled="formData.list.length  < 2"
          />
        </a-col>
      </a-row>
    </div>
    <a-row>
      <a-col :span="24">
        <a-button
          type="primary"
          ghost
          @click="onAdd"
          class="add-btn"
        >
          {{ $t('button.new') }}
        </a-button>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import {
  getFakeId,
  pointsSaveFormatter,
  pointsShowFormatter
} from "@/utils/tools";
import { showMsg } from "@/utils";
import { LocalStorageKey } from "./config";

const Empty = {
  year: null,
  points: null,
};

export default {
  name: "Employment",
  props: {},
  data() {
    return {
      pending: false,
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      formData: {
        list: [],
      },
    };
  },
  computed: {
  },
  methods: {
    onDelete(record, index) {
      this.formData.list.splice(index, 1);
    },
    async onAdd() {
      const valid = await this.onValid();
      if (!valid) return;
      this.formData.list.push({
        ...Empty,
        id: getFakeId(),
      });
    },
    onValid() {
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            resolve(false);
            showMsg({
              flag: 1,
              msg: Object.values(errors)[0]?.[0]?.message,
            });
            return;
          }
          // 查重
          for (let i = 0; i < this.formData.list.length; i++) {
            const item = this.formData.list[i];
            for (let j = 0; j < this.formData.list.length; j++) {
              const it = this.formData.list[j];
              if (i !== j && item.year === it.year) {
                resolve(false);
                showMsg({
                  flag: 1,
                  msg: this.$t("welfarePlat.companyBenefits.employment.yearUniq", { sn1: i + 1, sn2: j + 1 }),
                });
                return;
              }
            }
          }
          resolve(true);
        });
      });
    },
    async onSave() {
      const saveList = this.formData.list.map((it) => {
        return {
          ...it,
          points: pointsSaveFormatter(it.points),
        };
      });
      // 本地存储
      const valid = await this.onValid();
      if (!valid) return false;
      window.localStorage.setItem(LocalStorageKey, JSON.stringify(saveList));
      return saveList;
    },
    init() {
      const local = window.localStorage.getItem(LocalStorageKey);
      if (local) {
        try {
          const list = JSON.parse(local);
          this.formData.list = list.map((it) => {
            return {
              ...it,
              points: pointsShowFormatter(it.points, true),
            };
          });
        } catch (err) {
          console.log(err);
        }
      }
      if (!this.formData.list.length) {
        this.onAdd();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.list-wrapper {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 15px;
}
.list-sn {
  height: 46px;
  line-height: 46px;
  text-align: center;
}
.del-btn {
  color: @error-color;

  &[disabled="disabled"] {
    color: @disabled-color;
  }
}
.add-btn {
  width: 100%;
}
</style>
