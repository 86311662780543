import api from "@/utils/fetch"
import { queryUrlFormatter } from "@/utils/tools";

// 获取福利类型-分页
export function pageSysBenefits (params) {
  return api.post("/sys/benefits/page", params)
}

// 删除系统福利
export function delSysBenefits(params) {
  const url = queryUrlFormatter("/sys/benefits/del", params);
  return api.post(url, params);
}

// 新增系统福利
export function saveSySBenefits(params) {
  return api.post("/sys/benefits/save", params);
}

// HR 发放积分
export function grantPoints(params) {
  return api.post("/company/points/grant", params)
}
// 福利发放记录
export function getGrantRecords (params) {
  return api.post("/company/benefits/points/grant/page", params)
}

/**
 * 平台配置保存
 * @param params
 * @param {number=} params.id
 * @param {string=} params.logo
 * @param {string=} params.moneyName
 * @param {string=} params.name
 * @param {boolean=} params.tool
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function savePlatformConf(params) {
  return api.post("/company/platform/conf/save", params);
}

/**
 * 平台配置详情
 * @param {Object=} params
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function getPlatformConf(params) {
  return api.get("/company/platform/conf/info", params);
}

/**
 * 新增-更新公司福利
 * @param params
 * @param {string} params.companyId 企业id
 * @param {string=} params.grantTime 发放时间（可设置到小时）
 * @param {number=} params.id 主键id
 * @param {boolean} params.isAuto 是否自动发送
 * @param {string} params.name 福利名称
 * @param {string=} params.remark 备注
 * @param {boolean} params.state 	状态（true启用，false禁用）
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function saveBenefits(params) {
  return api.post("/company/benefits/save", params);
}

/**
 * 删除公司福利
 * @param params
 * @param {number} params.id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function delBenefits(params) {
  let url = "/company/benefits/del";
  url = queryUrlFormatter(url, params);
  return api.post(url, params);
}

/**
 * 查询公司福利列表-分页
 * @param params
 * @param {number} params.pageNum 	当前页页码
 * @param {number} params.pageSize 每页的数量
 * @param {string=} params.companyId 	企业id
 * @param {string=} params.search 	模糊搜索（福利名称等）
 * @param {boolean=} params.isAuto 是否自动
 * @param {boolean=} params.isSysBenefits 是否系统福利
 * @param {boolean=} params.state 启用状态
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function pageBenefits(params) {
  return api.post("/company/benefits/page", params);
}

/**
 * 查询福利积分发放记录集合-分页
 * @param params
 * @param {number} params.pageNum  当前页页码
 * @param {number} params.pageSize 每页的数量
 * @param {number=} params.benefitsId 福利id
 * @param {string=} params.companyId 企业id
 * @param {string=} params.grantEndTime 积分发送结束时间
 * @param {string=} params.grantStartTime 积分发送开始时间
 * @param {boolean=} params.isSuccess 是否成功
 * @param {string=} params.userId 用户Id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function pointsBenefits(params) {
  return api.post("/company/benefits/points/grant/page", params);
}

/**
 * 查询企业账单列表-分页
 * @param params
 * @param {number} params.pageNum
 * @param {number} params.pageSize
 * @param {string=} params.companyId 企业id
 * @param {string=} params.currency 	币种
 * @param {string=} params.formUserId 	来源用户Id
 * @param {string=} params.otherId 其他id（福利Id、用户建议id）
 * @param {string=} params.purpose 	用途
 * @param {string=} params.userId 	用户Id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function pagePointsBill(params) {
  return api.post("/company/points/bill/page", params);
}
// 消费记录
export function pageConsumptionRecord(params) {
  return api.post("/company/points/user/consumption/statistics/page", params);
}

// 公司员工数、活跃度
export function getCompanyUserCount(params) {
  return api.get("/company/staff/companyUserCount", params);
}
// 导出消费记录
export function consumptionRecordExport(params) {
  return api.download("/company/points/user/consumption/statistics/export", params);
}

// 发放福利模板
export function getBenefitTemplate(params) {
  return api.download("/company/benefits/grant/template/download", params);
}

// 批量发放福利
export function batchGrant(params) {
  return api.download("/company/points/batch/grant", params);
}

