<template>
  <a-modal
    :title="$t('welfarePlat.companyBenefits.formTitle', { name: title })"
    :visible="visible"
    @cancel="onCancel"
    @ok="onOk"
    class="welfare-type-form-modal"
    :maskClosable="false"
    destroyOnClose
  >
    <CompanyWelfareForm
      ref="indexRef"
      @close="onCancel"
      @reload="onReload"
      :data="data"
      :show="show"
      :companyId="companyId"
      :system="system"
    />
  </a-modal>
</template>

<script>
import CompanyWelfareForm from "@/pages/home/components/welfare/CompanyWelfare/Form.vue";
import {
  COMPANY_WELFARE_SHOW
} from "@/pages/home/components/welfare/CompanyWelfare/config";

export default {
  name: "CompanyWelfareFormModal",
  components: { CompanyWelfareForm },
  props: {
    visible: Boolean,
    data: Object,
    show: {
      type: Number,
      default: COMPANY_WELFARE_SHOW.ALL,
    },
    companyId: {
      type: String,
      default: undefined,
    },
    system: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    title() {
      if (this.system) {
        return this.$t('system.benefits.title');
      }
      return this.$t('welfarePlat.companyBenefits.title');
    },
  },
  methods: {
    onOk() {
      this.$refs.indexRef.onSubmit();
    },
    onCancel() {
      this.$refs.indexRef.onReset();
      this.$emit("update:visible", false);
    },
    onReload() {
      this.$emit("reload");
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
