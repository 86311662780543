<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    :label-col="calcFormItemLayout([6, 8]).labelCol"
    :wrapper-col="calcFormItemLayout([6, 8]).wrapperCol"
  >
    <a-row>
      <!--#region 福利名称-->
      <a-col :span="24">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.name.label')"
          prop="name"
        >
          <a-input
            v-model="form.name"
            :placeholder="$t('welfarePlat.companyBenefits.name.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 福利类型-->
      <a-col :span="24">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.benefitsType.label')"
          prop="benefitsType"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            style="position: relative;"
            v-model="form.benefitsType"
            :placeholder="$t('welfarePlat.companyBenefits.benefitsType.placeholder')"
            :disabled="!system"
            @change="(val) => onChange('benefitsType', val)"
          >
            <a-select-option
              v-for="op in benefitsTypeList"
              :key="op.dictCode"
              :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 发放公司-->
      <a-col :span="24" v-if="!system">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.companyId.label')"
          prop="companyId"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            style="position: relative;"
            v-model="form.companyId"
            :placeholder="$t('welfarePlat.companyBenefits.companyId.placeholder')"
            allowClear
            :disabled="isFixedCompany"
          >
            <a-select-option
              v-for="op in companyList"
              :key="op.companyId"
              :value="op.companyId"
            >
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 发放对象-->
      <a-col :span="24" v-if="!system">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.grantObject.label')"
          prop="grantObject"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            style="position: relative;"
            v-model="form.grantObject"
            :placeholder="$t('welfarePlat.companyBenefits.grantObject.placeholder')"
            @change="(val) => onChange('grantObject', val)"
          >
            <a-select-option
              v-for="op in targetList"
              :key="op.dictCode"
              :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 发放用户-->
      <a-col :span="24" v-if="form.grantObject === 'appoint'">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.userIds.label')"
          prop="userIds"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            style="position: relative;"
            mode="multiple"
            v-model="form.userIds"
            :placeholder="$t('welfarePlat.companyBenefits.userIds.placeholder')"
            allow-clear
          >
            <a-select-option
              v-for="op in staffList"
              :key="op.userId"
              :value="op.userId"
            >
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 发放时间-->
      <a-col :span="24" v-if="grantTimeVisible">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.grantTime.label')"
          prop="grantTime"
        >
          <a-date-picker
            v-model="form.grantTime"
            :placeholder="$t('welfarePlat.companyBenefits.grantTime.placeholder')"
            show-time
            style="width: 100%;"
            :disabled="grantTimeDisabled"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24" v-if="form.benefitsType === 'birthday_benefits'">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.grantTime.label')"
        >
          {{ $t('welfarePlat.companyBenefits.grantTime.birthday') }}
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 司龄-->
      <a-col :span="24" v-if="form.benefitsType === 'division_age_benefits'">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.employment.label')"
          prop="employment"
          class="company-benefits-addon"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            style="position: relative;"
            mode="multiple"
            class="company-benefits-addon-control"
            v-model="form.employment"
            :placeholder="$t('welfarePlat.companyBenefits.employment.placeholder')"
            allow-clear
          >
            <a-select-option
              :value="i.id"
              v-for="i in employmentList"
              :key="i.id"
            >
              {{ i.year }}{{ $t("datetime.unit.year") }}({{ i.pointsShow }})
            </a-select-option>
          </a-select>
          <a-button
            class="company-benefits-addon-item"
            type="link"
            size="large"
            @click="onEmployment"
          >
            {{ $t("welfarePlat.companyBenefits.employment.btn") }}
          </a-button>
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 发放数量-->
      <a-col :span="24" v-if="pointsVisible">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.num.label')"
          prop="num"
        >
          <a-input-number
            v-model="form.points"
            :placeholder="$t('welfarePlat.companyBenefits.num.label')"
            :min="0"
            :precision="2"
            style="width: 100%;"
          />
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 激活状态-->
      <a-col :span="24">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.state.label')"
          prop="state"
        >
          <a-switch v-model="form.state" />
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 自动发放-->
      <a-col :span="24">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.isAuto.label')"
          prop="isAuto"
        >
          <a-switch
            v-model="form.isAuto"
            :disabled="isFixedAuto"
          />
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 系统福利-->
      <a-col :span="24">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.isSysBenefits.label')"
          prop="isSysBenefits"
        >
          <a-switch v-model="form.isSysBenefits" disabled />
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
      <!--#region 备注-->
      <a-col :span="24">
        <a-form-model-item
          :label="$t('welfarePlat.companyBenefits.remark.label')"
          prop="remark"
        >
          <a-textarea
            v-model="form.remark"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :placeholder="$t('welfarePlat.companyBenefits.remark.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <!--#endregion-->
    </a-row>
    <EmploymentModal
      :visible.sync="employmentVisible"
      @load="getEmploymentList"
    />
  </a-form-model>
</template>

<script>
import { listCompany } from "@/pages/system/api";
import { showMsg } from "@/utils";
import { DateFormatFull, FETCH_CODE } from "@/config";
import moment from "moment/moment";
import {
  getFakeId,
  listPointsShowFormatter,
  pointsSaveFormatter,
  pointsShowFormatter
} from "@/utils/tools";
import {
  saveBenefits,
  saveSySBenefits
} from "@/pages/workspace/welfarePlatform/api";
import {
  COMPANY_WELFARE_SHOW
} from "@/pages/home/components/welfare/CompanyWelfare/config";
import { getDic } from "@/api";
import { pageStaff } from "@/pages/HR/api";
import EmploymentModal
  from "@/pages/home/components/welfare/CompanyWelfare/EmploymentModal.vue";
import { LocalStorageKey } from "./config";
import { mapState } from "vuex";
import { langMixin } from "@/mixins";

const FormEmpty = {
  name: null,
  companyId: undefined,
  grantObject: "all",
  benefitsType: "nomal_benefits",
  userIds: [],
  employment: [],
  grantTime: null,
  state: true,
  isAuto: false,
  isSysBenefits: false,
  remark: null,
  points: 0,
};

export default {
  name: "CompanyWelfareForm",
  components: { EmploymentModal },
  props: {
    data: Object,
    show: {
      type: Number,
      default: COMPANY_WELFARE_SHOW.ALL,
    },
    // 传入公司id时 公司可编辑
    companyId: {
      type: String,
      default: undefined,
    },
    system: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [langMixin],
  data() {
    return {
      pending: false,
      form: { ...FormEmpty },
      rules: {
        name: [
          { required: true, message: this.$t("welfarePlat.companyBenefits.name.required") },
          { max: 20, message: this.$t("welfarePlat.companyBenefits.name.max", { length: 20 }) },
        ],
        benefitsType: [
          { required: true, message: this.$t("welfarePlat.companyBenefits.benefitsType.required") },
        ],
        companyId: [
          { required: true, message: this.$t("welfarePlat.companyBenefits.companyId.required") },
        ],
        grantObject: [
          { required: true, message: this.$t("welfarePlat.companyBenefits.grantObject.required") },
        ],
        employment: [
          { required: true, message: this.$t("welfarePlat.companyBenefits.employment.required") },
        ],
        userIds: [
          {
            required: true,
            message: this.$t("welfarePlat.companyBenefits.grantObject.required"),
          },
        ],
        grantTime: [],
        points: [
          { required: true, message: this.$t("welfarePlat.companyBenefits.num.required") },
        ],
        remark: [
          { max: 255, message: this.$t("welfarePlat.companyBenefits.remark.max", { length: 255 }) },
        ],
      },
      companyList: [],
      // 发放对象列表
      targetList: [],
      // 福利类型
      benefitsTypeList: [],
      // 员工列表
      staffList: [],
      employmentList: [],
      employmentVisible: false,
    };
  },
  computed: {
    isFixedAuto() {
      return [COMPANY_WELFARE_SHOW.AUTO, COMPANY_WELFARE_SHOW.MANUAL].includes(this.show);
    },
    isFixedCompany() {
      return !this.companyId;
    },
    grantTimeDisabled() {
      if (!this.system && this.form.isSysBenefits) {
        return true;
      }
      return false;
    },
    grantTimeVisible() {
      if (this.form.benefitsType === 'division_age_benefits') {
        return false;
      }
      if (this.form.benefitsType === 'birthday_benefits') {
        return false;
      }
      return true;
    },
    pointsVisible() {
      if (this.form.benefitsType === 'division_age_benefits') {
        return false;
      }
      return true;
    },
    ...mapState({
      platformConf: state => state.user.platformConf,
    }),
  },
  methods: {
    async getCompanyList() {
      this.companyList = [];
      const res = await listCompany({
        name: "",
      });
      this.companyList = res.data || [];
    },
    onSubmit() {
      if (this.pending) return;
      this.pending = true;
      this.$refs.formRef.validate(async (valid, errors) => {
        if (!valid) {
          const msg = Object.values(errors)[0]?.[0]?.message;
          showMsg({ flag: FETCH_CODE.WARNING.KEY, msg });
          this.pending = false;
          return;
        }
        const params = {
          ...this.form,
          grantTime: this.form.grantTime ? moment(this.form.grantTime).format(DateFormatFull) : null,
          points: this.form.points ? pointsSaveFormatter(this.form.points) : 0,
        };
        if (params.benefitsType === "division_age_benefits") {
          const grantRuleJson = params.employment.map((id) => {
            const curr = this.employmentList.find((it) => it.id === id);
            return {
              ...curr,
              id: undefined,
            }
          });
          params.grantRuleJson = JSON.stringify(grantRuleJson);
        }
        let res;
        if (this.system) {
          res = await saveSySBenefits(params);
        } else {
          res = await saveBenefits(params);
        }
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.pending = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("showMsg.success.save"),
        });
        this.$emit("close");
        this.$emit("reload");
        this.onReset();
        this.pending = false;
      });
    },
    onReset() {
      this.form = { ...FormEmpty };
    },
    // 获取发放对象列表
    async getTargetList() {
      const res = await getDic({
        code: "benefits_grant_object",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.targetList = res.data;
    },
    // 获取福利类型列表
    async getBenefitsTypeList() {
      const res = await getDic({
        code: "benefits_type",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.benefitsTypeList = res.data;
    },
    async init() {
      await this.getCompanyList();
      await this.getTargetList();
      await this.getBenefitsTypeList();
      this.getEmploymentList();
      // 默认当前登录公司
      if (!this.system) {
        this.form.companyId = this.$store.state.user.info.currentCompany.companyId;
      }
      if (this.show === COMPANY_WELFARE_SHOW.AUTO) {
        this.form.isAuto = true;
      }
      this.form = {
        ...this.form,
        ...this.data,
      };
      this.form.userIds = this.form.userIds || [];
      if (this.form.grantObject === "appoint") {
        this.getStaffList();
      }
      if (this.form.benefitsType === "division_age_benefits") {
        try {
          const employment = [];
          const employmentList = this.employmentList.slice();
          const grantRuleJson = JSON.parse(this.form.grantRuleJson);
          // 回显司龄数据 如果本地存储没有自动创建
          grantRuleJson.forEach((it) => {
            const curr = employmentList.find((_it) => _it.year === it.year);
            // 如果本地存储存在
            if (curr) {
              curr.points = it.points;
              curr.pointsShow = pointsShowFormatter(it.points);
              employment.push(curr.id);
            }
            // 本地存储不存在 进行创建
            else {
              const id = getFakeId();
              employmentList.push({
                id,
                year: it.year,
                points: it.points,
                pointsShow: pointsShowFormatter(it.points),
              });
              employment.push(id);
            }
          });
          this.employmentList = employmentList;
          this.form.employment = employment;
          window.localStorage.setItem(LocalStorageKey, JSON.stringify(employmentList));
        } catch (err) {
          console.log(err);
        }
      }
    },
    // 获取员工列表
    async getStaffList() {
      if (!this.form.companyId) return;
      const res = await pageStaff({
        pageSize: 999,
        pageNum: 1,
        companyId: this.form.companyId,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.staffList = res.data.records;
    },
    onEmployment() {
      this.employmentVisible = true;
    },
    getEmploymentList(list) {
      if (!list) {
        const local = window.localStorage.getItem(LocalStorageKey);
        if (local) {
          try {
            const employmentList = JSON.parse(local);
            this.employmentList = listPointsShowFormatter(employmentList, ["points"]);
          } catch (err) {
            console.log(err);
          }
        }
        return;
      }
      console.log(list);
      this.employmentList = listPointsShowFormatter(list, ["points"]);
    },
    // 修改表单事件
    onChange(field, value) {
      // 福利类型
      if (field === "benefitsType") {
        // 切换司龄
        if (value === "division_age_benefits") {
          this.form.grantTime = null;
          this.form.points = null;
        } else {
          this.form.employment = [];
        }
        if (value === "birthday_benefits") {
          this.form.grantTime = null;
        }
      }
      // 发放对象
      if (field === "grantObject") {
        if (value === "appoint") {
          this.getStaffList();
        } else {
          this.form.userIds = [];
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.company-benefits-addon {
  /deep/.ant-form-item-children {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .company-benefits-addon-control {
      flex: 1;
    }
    .company-benefits-addon-item {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}
</style>
