<template>
  <a-modal
    :title="$t('welfarePlat.companyBenefits.employment.label')"
    :visible="visible"
    @cancel="onCancel"
    @ok="onOk"
    class="welfare-employment-modal"
    :maskClosable="false"
    destroyOnClose
  >
    <Employment ref="indexRef" />
  </a-modal>
</template>

<script>
import Employment
  from "@/pages/home/components/welfare/CompanyWelfare/Employment.vue";

export default {
  name: "EmploymentModal",
  components: { Employment },
  props: {
    visible: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.$emit("update:visible", false);
    },
    async onOk() {
      const res = await this.$refs.indexRef.onSave();
      if (!res) return;
      this.onCancel();
      this.$emit("load", res);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
