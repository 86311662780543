import { render, staticRenderFns } from "./EmploymentModal.vue?vue&type=template&id=85b7f56a&scoped=true"
import script from "./EmploymentModal.vue?vue&type=script&lang=js"
export * from "./EmploymentModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85b7f56a",
  null
  
)

export default component.exports